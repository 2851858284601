import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { postData } from "./actions";

// import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
// import { CoreParagraphBlock } from "../blocks/CoreParagraphBlock";

import styles from "./Form.styles";

const useStyles = makeStyles((theme) => styles(theme));

function Form({ course, dates, startDate, endDate, location }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState();
  const [company, setCompany] = useState("");
  const [postcode, setPostcode] = useState("");
  const [comments, setComments] = useState("");
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename: name,
      surname,
      email,
      telephone: tel,
      company,
      company_postcode: postcode,
      comments,
      course: `${course.courses.code} ${
        typeof dates !== "undefined" ? dates.startDate : "no date"
      } - ${typeof dates !== "undefined" ? dates.endDate : "no date"} ${
        typeof dates !== "undefined" ? dates.location : "no location"
      }`,
    });

    request.then((res) => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    });
  }

  return (
    <div className="enquiry-form">
      {formLocked && (
        <p className={classes.success}>
          Thank you. A member of the team will get back to your shortly.
        </p>
      )}

      {!formLocked && (
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <fieldset>
            <label for="name">First Name*</label>
            <input
              type="text"
              name="name"
              placeholder="John"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <label for="surname">Last Name*</label>
            <input
              type="text"
              name="surname"
              placeholder="Smith"
              required
              onChange={(e) => setSurname(e.target.value)}
            />

            <label for="email">Email*</label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <label for="phone">Phone Number*</label>
            <input
              type="tel"
              name="phone"
              placeholder="0778 8911 339"
              required
              onChange={(e) => setTel(e.target.value)}
            />

            <label for="company">Company Name</label>
            <input
              type="text"
              name="company"
              placeholder="Bosch"
              onChange={(e) => setCompany(e.target.value)}
            />

            <label for="postcode">Company Postcode</label>
            <input
              type="text"
              name="postcode"
              placeholder="SY7 9QS"
              onChange={(e) => setPostcode(e.target.value)}
            />

            <label for="comments">Comments</label>
            <textarea
              name="comments"
              placeholder="Comments here..."
              onChange={(e) => setComments(e.target.value)}
            />

            <input type="submit" value="Submit" id="CourseEnquiryFormSubmit" />

            <p className="required">*Field is required</p>
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default Form;
