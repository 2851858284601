export default (theme) => ({
  form: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    "& h2, & p": {
      marginBottom: 24,
      color: "white",
    },
    "& .required": {
      marginBottom: 0,
      fontSize: 12,
      letterSpacing: "0.0152em",
      textTransform: "uppercase",
      padding: "20px 0",
    },
    "& fieldset": {
      border: "none",
      padding: 0,
      "& label": {
        color: theme.palette.primary.contrastText,
        fontSize: 12,
        lineHeight: 1.6,
        letterSpacing: "0.0152em",
        textTransform: "uppercase",
        margin: 0,
        fontFamily: theme.bodyFont,
      },
      " & input, textarea": {
        fontFamily: theme.bodyFont,
        backgroundColor: "transparent",
        borderRadius: 20,
        fontSize: 12,
        lineHeight: 2,
        padding: "7px 20px",
        border: "1px solid " + theme.palette.primary.contrastText,
        marginBottom: 24,
        color: theme.palette.primary.contrastText,
        outline: "none",
        display: "block",
        width: "100%",
      },
      '& input[type="submit"]': {
        width: "auto",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.headerFont,
        fontSize: 16,
        float: "right",
        display: "block",
        margin: "0 0 0 auto",
        padding: "14px 40px",
        transition: "background-color .3s ease-in-out",
        letterSpacing: "0.016em",
        textAlign: "center",
        backgroundPosition: "center right 24px",
        backgroundRepeat: "no-repeat",
        outline: "none",
        boxShadow: "0 8px 10px rgb(0 0 0 0.8)",
        cursor: "pointer",
        borderRadius: 32,
        border: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
  },
  success: {
    color: theme.palette.primary.contrastText,
    letterSpacing: "0.016em",
  },
});
