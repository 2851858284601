import React, { Component } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import dateformat from "dateformat";
import ReactHtmlParser from "react-html-parser";

import Layout from "../components/layout";
import Form from "../components/CourseEnquiry/Form";
import Breadcrumb from "../components/Breadcrumb";
import { withStyles } from "@material-ui/styles";

import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock";

const styles = {
  enquiryPage: {
    marginTop: 40,
    "& .enquiry-wrap": {
      margin: "50px auto",
      "& .content, & .form": {
        padding: 32,
        backgroundColor: "#1b365d",
        flex: "0 0 100%",
        minWidth: "100%",
      },
      "@media (min-width: 960px)": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
        "& .content": {
          flex: "0 0 61.8%",
          minWidth: "61.8%",
          padding: 80,
        },
        "& .form": {
          flex: "0 0 calc(38.2% - 1px)",
          minWidth: "calc(38.2% - 1px)",
          padding: "80px 80px 80px 40px",
        },
      },
      "@media (min-width: 1280px)": {
        maxWidth: "87.5%",
      },
      "& .content": {
        "& .course-info": {
          backgroundColor: "white",
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          padding: 24,
          "@media (min-width: 960px)": {
            padding: "50px 80px",
          },
          "& h3": {
            margin: "0 0 36px",
            fontSize: 24,
            color: "#c8102e",
            letterSpacing: "0.016em",
            lineHeight: 1,
          },
          "& .dates": {
            fontFamily: "Bebas Neue",
            color: "#c8102e",
            fontSize: 22,
            letterSpacing: "0.016em",
            lineHeight: 1.5,
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 100,
            margin: 0,
            marginBottom: 14,
            "& > span": {
              display: "block",
              textAlign: "center",
              "& > span": {
                display: "block",
                fontSize: 43,
                lineHeight: 0.75,
              },
            },
          },
          "& .title": {
            fontFamily: "Overpass",
            letterSpacign: "0.016em",
            lineHeight: 1.5,
            fontSize: 16,
            fontWeight: "bold",
            color: "#1B365D",
            margin: "0 0 16px",
          },
          "& .extras": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start",
            "& p": {
              fontSzie: 12,
              lineHeight: 1.5,
              letterSpacing: "0.016em",
              flex: "0 0 50%",
              minWidth: "50%",
              margin: 0,
              fontFamily: "Overpass",
              "@media (min-width: 960px)": {
                flex: "0 0 40%",
                minWidth: "40%",
              },
              "& span": {
                fontFamily: "Roboto Condensed",
                fontSize: 10,
                textTransform: "uppercase",
                opacity: 0.3,
                display: "block",
                letterSpacing: "0.152em",
              },
            },
          },
        },
        "& .course-details p": {
          color: "white",
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacign: "0.016em",
          margin: "32px 0",
        },
      },
    },
  },
  detailsBlock: {},
};

const queryObject = (param) => ({
  query: `
  {
    course(id: "${param}") {
      id
      title
      courses {
        code
        credits
        description
        fieldGroupName
        length
        memberPrice
        tradePrice
        type
      }
    }
  }
  `,
});

const queryObjectCourseDates = (next = "") => {
  return {
    query: `
    {
      courseDates(first: 50, after: "${next}") {
        edges {
          node {
            id
            title
            uri
            courseData {
              course {
                ... on Course {
                  id
                  databaseId
                }
              }
              startDate
              endDate
              seatsAvailable
              seatsBooked
              location
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

function formatDateToNames(date) {
  // date is "20230620", needs to be "[] d [/]mmm"
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);

  const newDate = new Date(`${year}-${month}-${day}`);

  return dateformat(newDate, "[] d [/]mmm");
}

function Course({
  course,
  courseDates,
  courseId,
  dateId,
  startDate,
  endDate,
  location,
}) {
  if (!course && !courseDates) return null;

  const { title } = course;
  const { length, description } = course.courses;
  // const dates =
  //   courseDates &&
  //   courseDates.find((course) => course.id === dateId)?.courseData;

  return (
    <div>
      <div className="course-info">
        <h3>Details</h3>
        {course.startDate && course.endDate && (
          <div className="dates">
            <span className="from">
              {ReactHtmlParser(
                formatDateToNames(course.startDate)
                  .replace("[]", "<span>")
                  .replace("[/]", "</span>")
              )}
            </span>
            -
            <span className="to">
              {ReactHtmlParser(
                formatDateToNames(course.endDate)
                  .replace("[]", "<span>")
                  .replace("[/]", "</span>")
              )}
            </span>
          </div>
        )}

        {course?.title ? <p className="title">{course.title}</p> : null}
        <div className="extras">
          {course.location && (
            <p>
              <span>Location</span> {course.location}
            </p>
          )}
          {length && (
            <p>
              <span>Length</span> {length}
            </p>
          )}
        </div>
      </div>

      <div
        className="course-details"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
}

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };

    if (!this.props.location.state) return;
    const courseId = this.props.location.state.courseId;
    if (!courseId) return null;

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObject(this.props.location.state.courseId),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.errors) {
          console.error("error", res.data.errors);
          return;
        }
        this.setState({
          course: res.data.data.course,
        });
      });

    // this.fetchCourseDates();
  }

  fetchCourseDates(next = "", prevData = []) {
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCourseDates(next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          courseDates: res.data.data.courseDates.edges.map((node) => node.node),
          next: res.data.data.courseDates.pageInfo.endCursor,
          hasNextPage: res.data.data.courseDates.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        if (data.hasNextPage) {
          this.fetchCourseDates(data.next, prevData.concat(data.courseDates));
        } else {
          this.setState({
            courseDates: prevData.concat(data.courseDates),
          });
        }
      });
  }

  render() {
    const { course } = this.state;
    const dateId =
      this.props.location.state && this.props.location.state.dateId;
    // const dates =
    //   this.state.courseDates &&
    //   this.state.courseDates.find((course) => course.id === dateId)?.courseData;

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/course-enquiry/",
        }}
        path={"/course-enquiry/"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="page"
          current={{
            title: "Enquiry",
            slug: "enquiry",
            uri: "/enquiry",
          }}
        />
        <div className={this.props.classes.enquiryPage}>
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: "Course Enquiry",
              level: 1,
              backgroundColor: "",
              textColor: "primary",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <div className="enquiry-wrap">
            <div className="content">
              <Course
                course={course}
                courseDates={this.state.courseDates}
                courseId={
                  this.props.location.state &&
                  this.props.location.state.courseId
                }
                dateId={
                  this.props.location.state && this.props.location.state.dateId
                }
                startDate={this?.props?.location?.state?.startDate}
                endDate={this?.props?.location?.state?.endDate}
                location={this?.props?.location?.state?.location}
              />
            </div>
            <div className="form">
              <Form
                course={course}
                startDate={this?.props?.location?.state?.startDate}
                endDate={this?.props?.location?.state?.endDate}
                location={this?.props?.location?.state?.location}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default withStyles(styles)(Page);
